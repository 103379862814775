import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockReset from '@mui/icons-material/LockReset';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from "../components/Footer";
import Checkbox from "@mui/material/Checkbox";
import {ChangeEvent, useEffect, useRef, useState} from "react";
import {Alert, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import { CheckCircleOutline, CancelOutlined } from '@mui/icons-material';
import {useSearchParams, useNavigate, Link as LinkRouter} from "react-router-dom";
import {isSecurePassword} from '../utils'
import axios from "../api/axios";
import {jwtDecode} from 'jwt-decode';
import { CustomJwtPayload } from '../types';
import Link from "@mui/material/Link";


const defaultTheme = createTheme();




const PASSWORD_RESET_URL = '/users/password-reset';

export default function PasswordResetPage() {
    const alertRef = useRef<HTMLDivElement>(null); // Ref for the alert component
    const [showPassword, setShowPassword] = React.useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState<string|null>(null);
    const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(()=>{
        const accessToken = searchParams.get('access_token');
        if(accessToken === null || accessToken === ''){
            navigate('/');
            return;
        }
        const currentDate = new Date();
        const decodedPayload = jwtDecode<CustomJwtPayload>(accessToken);
        if (decodedPayload !== undefined && decodedPayload.exp !== undefined ) {
            if (decodedPayload.exp * 1000 < currentDate.getTime()) {
                // console.log("Token expired.");
                setIsTokenExpired(true);
                setErrMsg('Password reset time expired, request another reset.');
            } else {
                // console.log("Valid token");
                setIsTokenExpired(false);
            }
        }
        setToken(accessToken);
    });

    useEffect(() => {
        // Move focus to the alert when it's rendered
        if (alertRef.current) {
            alertRef.current.focus();
        }
    }, [errMsg]); // Dependency added to run this effect whenever errMsg changes

    const handlePasswordChange1 = (e: ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;
        setPassword1(newPassword);
        setPasswordsMatch(newPassword === password2);
        setIsValidPassword(isSecurePassword(newPassword));
    };

    const handlePasswordChange2 = (e: ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;
        setPassword2(newPassword);
        setPasswordsMatch(newPassword === password1);
    };

    const handleCheckboxChange = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const pass1 = data.get('password1') as string;
        const pass2 = data.get('password2') as string;
        setIsTokenExpired(false);
        // Check if passwords match
        if (pass1 !== pass2) {
            setErrMsg('Passwords don\'t match');
            return;
        }
        if(!isValidPassword){
            setErrMsg('Password failed validation.');
            return;
        }

        if (token !== null) {
            const decodedPayload = jwtDecode<CustomJwtPayload>(token);
            const sub = decodedPayload.sub;
            if (sub.email !== undefined) {
                try {
                    const response = await axios.put(PASSWORD_RESET_URL,
                        JSON.stringify({email: sub.email, password: pass1}),
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                            withCredentials: true
                        }
                    );
                    navigate('/login');
                } catch (err: any) {
                    if (!err?.response) {
                        setErrMsg('No Server Response');
                    } else if (err.response?.status === 404) {
                        setErrMsg('Missing Username or Password');
                    } else if (err.response?.status === 401 && err.response.data.message === "The token has expired.") {
                        setErrMsg('Password reset time expired, request another reset.');
                        setIsTokenExpired(true);
                    } else {
                        setErrMsg('Login Failed');
                    }
                }
            }
        }else {
            console.error('Bad token.');
            return;
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                { errMsg || isTokenExpired? (
                <div tabIndex={-1} ref={alertRef}> {/* Alert component with ref */}
                    <Alert severity="error">
                        {errMsg}
                        {isTokenExpired && (
                            <>
                                <br/>
                                <Link component={LinkRouter} to={"/forgot-password"}>
                                    Request Reset
                                </Link>
                            </>
                        )}
                    </Alert>
                </div>
                ): (<div></div>)}
                {!isTokenExpired ?(
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockReset />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                    <Typography variant="h6">Password Security Standards:</Typography>
                    <List dense>
                        <ListItem>
                            <ListItemIcon>
                                {password1.length >= 12 ? <CheckCircleOutline style={{ color: 'green' }} /> : <CancelOutlined style={{ color: 'red' }} />}
                            </ListItemIcon>
                            <ListItemText primary="Minimum length of 12 characters." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {[/[A-Z]/].some(regex => regex.test(password1)) ? <CheckCircleOutline style={{ color: 'green' }} /> : <CancelOutlined style={{ color: 'red' }} />}
                            </ListItemIcon>
                            <ListItemText primary="At least one uppercase letter." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {[/[a-z]/].some(regex => regex.test(password1)) ? <CheckCircleOutline style={{ color: 'green' }} /> : <CancelOutlined style={{ color: 'red' }} />}
                            </ListItemIcon>
                            <ListItemText primary="At least one lowercase letter." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {[/\d/].some(regex => regex.test(password1)) ? <CheckCircleOutline style={{ color: 'green' }} /> : <CancelOutlined style={{ color: 'red' }} />}
                            </ListItemIcon>
                            <ListItemText primary="At least one number." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password1) ? <CheckCircleOutline style={{ color: 'green' }} /> : <CancelOutlined style={{ color: 'red' }} />}
                            </ListItemIcon>
                            <ListItemText primary="At least one special character (!@#$%^&*()_-)" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {/(password|123456|qwerty|etc)/i.test(password1) ? <CancelOutlined style={{ color: 'red' }} /> : <CheckCircleOutline style={{ color: 'green' }} />}
                            </ListItemIcon>
                            <ListItemText primary="Avoid common patterns (e.g., password123)" />
                        </ListItem>
                    </List>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password1"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            id="password1"
                            autoComplete="current-password"
                            value={password1}
                            onChange={handlePasswordChange1}
                            error={!isValidPassword || !passwordsMatch}
                            helperText={
                                !isValidPassword
                                    ? "Password must meet security requirements"
                                    : !passwordsMatch && "Passwords do not match"
                            }
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password2"
                            label="Confirm Password"
                            type={showPassword ? "text" : "password"}
                            id="password2"
                            autoComplete="current-password"
                            value={password2}
                            onChange={handlePasswordChange2}
                            error={!passwordsMatch}
                            helperText={!passwordsMatch && "Passwords do not match"}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                checked={showPassword}
                                onChange={handleCheckboxChange}
                            />
                            <Typography>Show Password</Typography>
                        </Box>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>): <Box></Box>}
                <Footer
                    title=""
                    description=""
                />
            </Container>
        </ThemeProvider>
    );
}
