import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from "../components/Footer";
import MainAppBar from "../components/MainAppBar";
import Paper from "@mui/material/Paper";
import EditorPostsTable from "../components/EditorPostsTable";
import {useNavigate} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import HomeHeader from "../components/HomeHeader";


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function PostBloggerListPage() {

    const { auth } = useAuth();
    const navigate = useNavigate()
    // const {user, logoutUser} = useAuth()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="lg">
                <HomeHeader title="Pitchblack408" sections={[]} />
                <MainAppBar/>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <EditorPostsTable />
                    </Paper>
                </Grid>
                <Footer
                    title=""
                    description=""
                />
            </Container>
        </ThemeProvider>
    );
}