import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const OptionalAuth = () => {
    const { auth } = useAuth();
    const location = useLocation();
    return (
            <Outlet />
    );
}

export default OptionalAuth;