import {createContext, ReactNode, useState} from "react";
import {RoutesProps} from "react-router-dom";




interface RoleType {
    id: number;
    name: string;
}

interface AuthType {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    roles: RoleType[];
    accessToken: string;
}


interface AuthStateType {
    auth: AuthType; // Define the type for auth as per your requirement
    persist: boolean;
}


export interface AuthContextType extends AuthStateType{
    // setAuth: React.Dispatch<React.SetStateAction<any>>;
    setAuth: (auth: any) => void;
    setPersist: (persist: any) => void;
}

const initialAuthState: AuthStateType = {
    auth: {
        id: -1,
        first_name: '',
        last_name: '',
        email: '',
        roles: [],
        accessToken: ''},
    persist: JSON.parse(localStorage.getItem("persist") || "false")
};


const AuthContext = createContext<AuthContextType>({
    ...initialAuthState,
    setAuth: () => {},
    setPersist: () => {},
});


export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [auth, setAuth] = useState<any>(initialAuthState.auth);
    const [persist, setPersist] = useState<boolean>(initialAuthState.persist);
    return (
        <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;