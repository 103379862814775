import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedIn from '@mui/icons-material/LinkedIn';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HomeHeader from '../components/HomeHeader';
import MainFeaturedPost from '../components/MainFeaturedPost';
import FeaturedPost from '../components/FeaturedPost';
import Main from '../components/Main';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import {useEffect, useState} from "react";
import MainAppBar from "../components/MainAppBar";
import axios, {axiosPrivate} from '../api/axios'
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";






export interface Post {
    id: number;
    url_title: string;
    title: string;
    description: string;
    body: string;
    created: Date;
    author_id: string;
    first_name: string;
    last_name: string;
}


export interface FeaturedPostType {
    date: string;
    description: string;
    image: string;
    imageLabel: string;
    title: string;
    href: string;
}



//Not using this currently, keeping it because I don't know if I want to permanently remove the toolbar
const sections = [
    { title: 'Technology', url: '#' },
    // { title: 'Design', url: '#' },
    // { title: 'Culture', url: '#' },
    // { title: 'Business', url: '#' },
    // { title: 'Politics', url: '#' },
    // { title: 'Opinion', url: '#' },
    // { title: 'Science', url: '#' },
    // { title: 'Health', url: '#' },
    // { title: 'Style', url: '#' },
    // { title: 'Travel', url: '#' },
];

const sidebar = {
    title: 'Welcome to Pitchblack408',
    description:
        'A Blog created for sharing information about software development, devops, data technology, and beer.',
    //Not using this currently, keeping it because I don't know if I want to permanently change the sidebar
    archives: [
        // { title: 'March 2020', url: '#' },
        // { title: 'February 2020', url: '#' },
        // { title: 'January 2020', url: '#' },
        // { title: 'November 1999', url: '#' },
        // { title: 'October 1999', url: '#' },
        // { title: 'September 1999', url: '#' },
        // { title: 'August 1999', url: '#' },
        // { title: 'July 1999', url: '#' },
        // { title: 'June 1999', url: '#' },
        // { title: 'May 1999', url: '#' },
        // { title: 'April 1999', url: '#' },
    ],
    social: [
        { name: 'GitHub', icon: GitHubIcon, url: "https://github.com/pitchblack408" },
        { name: 'LinkedIn', icon: LinkedIn, url: "https://linkedin.com/in/michael-martin-377b617" },
        // { name: 'X', icon: XIcon },

    ],
};

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function HomePage() {

    const refresh = useRefreshToken();
    const { auth } = useAuth();

    const [posts, setPosts] = useState([]);
    const [mainFeaturedPost, setMainFeaturedPost] = useState({
        title: '',
        description: '',
        image: '',
        imageText: '',
        linkText: '',
        href: '',
    });
    const [featuredPosts, setFeaturedPosts] = useState([]);

    const fetchPosts = async () => {

        const response = await axios('blog/posts?page=1&per_page=100&sort=-created');
        const data = await response.data;
        if (data && data.results && data.results.length > 0) {
            setPosts(data.results.map((p: Post) => ({
                "date": p.created,
                "author": p.first_name + " " + p.last_name,
                "description": p.description,
                "image": "https://source.unsplash.com/random?wallpapers",
                "imageLabel": "",
                "title": p.title,
                "href": '/post/' + p.url_title
            })));
            setMainFeaturedPost({
                title: data.results[0].title,
                "description": data.results[0].description,
                image: 'https://source.unsplash.com/random?wallpapers',
                imageText: 'main image description',
                linkText: 'Continue reading…',
                href: '/post/' + data.results[0].url_title
            })

            setFeaturedPosts(data.results.map((p: Post) => ({
                "date": p.created,
                "author": p.first_name + " " + p.last_name,
                "description": p.description,
                "image": "https://source.unsplash.com/random?wallpapers",
                "imageLabel": "",
                "title": p.title,
                "href": '/post/' + p.url_title
            })).slice(0, 4));
        }
    };

    useEffect(() => {
        fetchPosts();
    }, []);
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container maxWidth="lg">
                <HomeHeader title="Pitchblack408" sections={sections} />
               <MainAppBar/>
                <main>
                    <MainFeaturedPost post={mainFeaturedPost} />
                    <Grid container spacing={3}>
                        {featuredPosts.map((post: FeaturedPostType) => (
                            <FeaturedPost key={post.title} post={post} />
                        ))}
                    </Grid>
                    <Grid container spacing={5} sx={{ mt: 3 }}>
                        <Main header_title="Recent Posts" posts={posts} />
                        <Sidebar
                            title={sidebar.title}
                            description={sidebar.description}
                            archives={sidebar.archives}
                            social={sidebar.social}
                        />
                    </Grid>
                </main>
            </Container>
            <Footer
                title=""
                description=""
            />
        </ThemeProvider>
    );
}
