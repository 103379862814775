import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export default function ErrorPage() {
    return (
        <Box
            sx={{
                backgroundImage: "url('/images/spilled-milk.png')", // Correct the image path
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100vh", // Use viewport height for full-page coverage
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '@media (max-width: 600px)': { // Adjust styles for screens smaller than 600px
                    height: "40vh", // Adjust height for smaller screens
                },

            }}
        >
            <Typography variant="h5" sx={{
                fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem' }
            }}>
                Error 404 - Page Not Found
            </Typography>
        </Box>
    );

}