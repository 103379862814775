import * as React from 'react';
import Container from '@mui/material/Container';
import Footer from "../components/Footer";
import AuthorHeader from "../components/AuthorHeader";
import Paper from '@mui/material/Paper';
import Markdown from 'markdown-to-jsx';
import MainAppBar from '../components/MainAppBar'
import {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline'
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import HomeHeader from "../components/HomeHeader";


interface Post {
    id: number;
    urlTitle: string;
    title: string;
    description: string;
    body: string;
    created: string;
    author_id: number;
    first_name: string;
    last_name: string;
}



export default function PostBloggerPreviewPage() {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const {urlTitle} = useParams();
    const [data, setData] = useState<Post>({ id: -1,
                                                                                           urlTitle: '',
                                                                                           title: '',
                                                                                           description: '',
                                                                                           body: '',
                                                                                           created: '',
                                                                                           author_id: -1,
                                                                                           first_name: '',
                                                                                           last_name:''});

    useEffect(() => {

        const fetchData = async () => {
            try {
                console.log(urlTitle)
                const response = await axiosPrivate(`blog/posts/title/${urlTitle}`);
                const jsonData = await response.data;
                setData(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <div style={{padding: '8px', height: '100vh'}}>
            <HomeHeader title="Pitchblack408" sections={[]} />
            <CssBaseline />
            <Container component="main" maxWidth="lg">
                    <MainAppBar/>

                <Paper style={{width: '100%', height: '100%'}}>

                    <AuthorHeader title={data.title} author={`${data.first_name} ${data.last_name}`}  created={data.created}/>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px'}}>
                        <Markdown >{data.body}</Markdown>
                    </div>
                    </Paper>

                <Footer
                    title=""
                    description=""
                />
            </Container>
        </div>
    );
}