export function convertUtcDateStringToLocal(utcDateString) {
    const utcDate = new Date(utcDateString);
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return localDate.toLocaleDateString('en-US', options); // MMM dd, yyyy
}

export function convertUtcDateStringToLocalDatetime(utcDateString) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDate = new Date(utcDateString);
    const localDate = new Date(utcDate.toLocaleString(undefined, {timeZone: userTimeZone}));
    const options = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true // for 12-hour format
    };

    return localDate.toLocaleString(undefined, options);
}

export function convertUtcDateStringToLocalShortDate(utcDateString) {
    const utcDate = new Date(utcDateString);
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const result = localDate.toLocaleDateString('en-US', options)
    return result.substring(0, result.indexOf(',')); // MMM dd
}


export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


export function is_admin(roles) {
    for (let role in roles) {
        if(role.name === 'ADMIN') {
            return true;
        }
    }
    return false;
}



export function isSecurePassword(password) {
    // Define your password criteria
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
    const hasNoCommonPattern = !/(password|123456|qwerty|etc)/i.test(password);
    // Check if password meets all criteria
    return (
        password.length >= minLength &&
        hasUppercase &&
        hasLowercase &&
        hasNumber &&
        hasSpecialChar &&
        hasNoCommonPattern
    )
}


export function generateSecurePassword() {
    const length = 12;
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const numberChars = "0123456789";
    const specialChars = "!@#$%^&*()_-";
    const allChars = uppercaseChars + lowercaseChars + numberChars + specialChars;

    let password = "";

    // Ensure at least one character from each category
    password += getRandomChar(uppercaseChars);
    password += getRandomChar(lowercaseChars);
    password += getRandomChar(numberChars);
    password += getRandomChar(specialChars);

    // Fill the rest of the password with random characters
    for (let i = 4; i < length; i++) {
        password += getRandomChar(allChars);
    }

    // Shuffle the password to avoid common patterns
    password = shuffleString(password);

    return password;
}

function getRandomChar(charset) {
    return charset.charAt(Math.floor(Math.random() * charset.length));
}

function shuffleString(string) {
    let array = string.split('');
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array.join('');
}
