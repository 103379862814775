import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from "../components/Footer";
import MainAppBar from "../components/MainAppBar";
import {Link as LinkRouter, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import { Alert } from '@mui/material';
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Link from "@mui/material/Link";
import HomeHeader from "../components/HomeHeader";



const sections = [
    { title: 'Technology', url: '#' },

]

interface APIErrorResponse {
    status: string;
    status_code: number;
    error: {
        code: string;
        message: string;
        details: string;
        timestamp: string;
        path: string;
        suggestion: string | null;
    };
    request_id: string | null;
    documentation_url: string | null;
}

interface PostData {
    id?: number
    author_id: number;
    description: string;
    title: string;
    body: string;
}


type CombinedType = APIErrorResponse | PostData;

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function PostBloggerEditPage() {

    const {urlTitle} = useParams();
    const navigate = useNavigate();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const alertRef = useRef<HTMLDivElement>(null); // Ref for the alert component
    const [errMsg, setErrMsg] = useState('');
    const [postId, setPostId] = useState<number>(-1);
    const [firstNameForm, setFirstNameForm] = useState<string>('');
    const [lastNameForm, setLastNameForm] = useState<string>('');
    const [descriptionForm, setDescriptionForm] = useState<string>('');
    const [titleForm, setTitleForm] = useState<string>('');
    const [bodyForm, setBodyForm] = useState<string>('');
    const [authorId, setAuthorId] = useState<number>(-1);
    const [loadedPost, setLoadedPost] = useState<boolean>(false)


    useEffect(() => {
        if(urlTitle && !loadedPost) {
            fetchPost();
            setLoadedPost(true);
        }
    }, );


    useEffect(() => {
        // Move focus to the alert when it's rendered
        if (alertRef.current) {
            alertRef.current.focus();
        }
    }, [errMsg]); // Dependency added to run this effect whenever errMsg changes

    const fetchPost = async () => {
        try {

            const response = await axios(`blog/posts/title/${urlTitle}`);
            const data = await response.data;
            setPostId(data.id)
            setDescriptionForm(data.description);
            setTitleForm(data.title);
            setBodyForm(data.body);
            setFirstNameForm(data.first_name);
            setLastNameForm(data.last_name);
            setAuthorId(data.author_id);


        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrMsg('');
        try {
            const response = await axiosPrivate.put(`blog/posts/${postId}`,
                JSON.stringify({
                    author_id: authorId,
                    description: descriptionForm,
                    title: titleForm,
                    body: bodyForm
                }),
                {
                    headers: {'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            const jsonResponse = await response.data;
            navigate(`/blogger/post/preview/${jsonResponse.url_title}`)
        }catch (err: any) {
            if (!err?.response) {
                setErrMsg('No server response, updating the post failed.');
            } else if (err.response?.status === 409) {
                setErrMsg('The title must be unique, creating the post Failed.');
            }else if (err.response?.status === 400) {
                setErrMsg('Bad request, updating the post failed.');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized, updating the post failed.');
            } else {
                setErrMsg('Unexpected error, updating the post failed.');
            }
        }

    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container maxWidth="lg">
                <HomeHeader title="Pitchblack408" sections={[]} />
                <MainAppBar/>
                { errMsg ? (
                    <div tabIndex={-1} ref={alertRef}> {/* Alert component with ref */}
                        <Alert severity="error">
                            {errMsg}
                        </Alert>
                    </div>
                ): (<div></div>)}
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Edit Post
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    disabled
                                    fullWidth
                                    id="firstNameFormInput"
                                    label="Author's first Name"
                                    name="firstname"
                                    value={firstNameForm}
                                    variant="filled"
                                />

                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    disabled
                                    fullWidth
                                    id="lastNameFormInput"
                                    label="Author's last Name"
                                    name="lastName"
                                    value={lastNameForm}
                                    variant="filled"
                                />

                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="descriptionFormInput"
                                    label="Description"
                                    name="Description"
                                    value={descriptionForm}
                                    onChange={(e) => setDescriptionForm(e.target.value)}
                                    autoComplete={"off"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="titleFormInput"
                                    label="Title"
                                    name="Title"
                                    // autoComplete="family-name"
                                    value={titleForm}
                                    onChange={(e) => setTitleForm(e.target.value)}
                                    autoComplete={"off"}
                                />
                            </Grid>
                            <Grid item xs={12}  sm={12}>
                                <TextField
                                    required
                                    fullWidth
                                    multiline
                                    rows={35}
                                    id="bodyFormInput"
                                    label="Body"
                                    name="Body"
                                    value={bodyForm}
                                    onChange={(e) => setBodyForm(e.target.value)}
                                    // autoComplete="family-name"
                                />

                            </Grid>

                        </Grid>
                        <Box display="flex" justifyContent="center"> {/* Use flexbox to display items side by side */}
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mr: 1, mt: 3, mb: 2 }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Footer
                    title=""
                    description=""
                />
            </Container>
        </ThemeProvider>
    );
}