import {axiosPrivate} from "../api/axios";
import { useEffect } from "react"
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";


const useAxiosPrivate = () => {

    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {

        //The initial token is pulled with login,
        // but this will add the token to all other requests which are secured
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                // This isn't a retry, the retry will happen in the responseIntercept
                // because the response returns the 403.
                if(!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        )



        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const  prevRequest = error?.config;
                // This is the retry logic, it will grab a new token and then retry the prev request
                if (error?.response?.status === 403 && !prevRequest?.sent){
                    // true here is a new var we add so we only retry once
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosPrivate(prevRequest);

                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh]);

    return axiosPrivate;
}

export default useAxiosPrivate;