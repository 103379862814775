import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import {Link as LinkRouter, useLocation, useNavigate, useParams} from "react-router-dom";
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AppRegistration from '@mui/icons-material/AppRegistration';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from "../components/Footer";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {ChangeEvent, useEffect, useRef, useState} from "react";
import {generateSecurePassword} from '../utils'
import {Alert, Chip, FormControl, InputLabel, Select, SelectChangeEvent} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import CancelIcon from "@mui/material/Icon";
import HomeHeader from "../components/HomeHeader";
import MainAppBar from "../components/MainAppBar";
import useAuth from "../hooks/useAuth";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

interface RoleType {
    id: number;
    name: string;
}

export default function UserNewPage() {
    const navigate = useNavigate()
    const location = useLocation();
    const from = location.state?.from?.pathname || "/admin/users/edit";
    const axiosPrivate = useAxiosPrivate();
    const alertRef = useRef<HTMLDivElement>(null); // Ref for the alert component
    const [errMsg, setErrMsg] = useState('');
    // const [usernameForm, setUsernameForm]  = useState<string>('');
    const [userFirstNameForm, setUserFirstNameForm]  = useState<string>('');
    const [userLastNameForm, setUserLastNameForm]  = useState<string>('');
    const [userEmailForm, setUserEmailForm] = useState<string>('');
    const [userCreatedForm, setUserCreatedForm]  = useState<string>('');
    const [availableRoles, setAvailableRoles] = useState<RoleType[]>([]);
    const [selectedUserRolesForm, setSelectedUserRolesForm] = useState<RoleType[]>([]);

    const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUserFirstNameForm(event.target.value);
    };

    const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUserLastNameForm(event.target.value);
    };

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUserEmailForm(event.target.value);
    };

    useEffect(() => {
        fetchAvailableRoles(); // This will get all the roles that are already in the database and add them to availableRoles state
        // If this is a new user or we already loaded the user, we don't need to load a user

    }, []);


    useEffect(() => {
        // Move focus to the alert when it's rendered
        if (alertRef.current) {
            alertRef.current.focus();
        }
    }, [errMsg]); // Dependency added to run this effect whenever errMsg changes

    const fetchAvailableRoles = async () => {
        try {
            const response = await axiosPrivate(`/roles`);
            const data = await response.data;
            setAvailableRoles(data.results);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrMsg('');
        try {
            const response = await axiosPrivate.post('/users/admin/register_user',
                JSON.stringify({
                    password: generateSecurePassword(),
                    email: userEmailForm,
                    roles: selectedUserRolesForm,
                    first_name: userFirstNameForm,
                    last_name: userLastNameForm
                }),
                {
                    headers: {'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            const jsonResponse = await response.data;
            navigate('/admin/users/edit')
        } catch (err: any) {
            if (!err?.response) {
                setErrMsg('No server response, creating the user Failed.');
            } else if (err.response?.status === 409) {
                setErrMsg('The title must be unique, creating the user Failed.');
            } else if (err.response?.status === 400) {
                setErrMsg('Bad request, creating the user Failed.');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized, creating the user Failed.');
            } else {
                setErrMsg('Unexpected error, creating the user Failed.');
            }
        }

    };
    // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    // };

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const values = event.target.value as string[];
        const selected = availableRoles.filter(role => values.includes(role.name));
        setSelectedUserRolesForm(selected);
    };

    function handleCancel() {
        navigate(from, { replace: true });
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container maxWidth="lg">
            <HomeHeader title="Pitchblack408" sections={[]} />
            <MainAppBar/>
            <Container component="main" maxWidth="xs">
                { errMsg ? <Alert severity="error">{errMsg}</Alert> :<></>}
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <AppRegistration />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        New User
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label={"First Name"}
                                    // label={userFirstNameForm === '' ? 'First Name' : ''}
                                    value={userFirstNameForm}
                                    onChange={handleFirstNameChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    name="lastName"
                                    autoComplete="family-name"
                                    label={'Last Name'}
                                    value={userLastNameForm}
                                    onChange={handleLastNameChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    label={'Email'}
                                    value={userEmailForm}
                                    onChange={handleEmailChange}
                                />
                            </Grid>
                            <Grid  item xs={12}>
                                <FormControl variant="outlined" fullWidth required={true} >
                                <InputLabel>Roles</InputLabel>
                                <Select
                                    label="Roles"
                                    multiple={true}
                                    value={selectedUserRolesForm.map(role => role.name)}
                                    onChange={handleChange}
                                    renderValue={(selected :string[]) => (
                                        <Stack gap={1} direction="row" flexWrap="wrap">
                                            {(selected as string[]).map((value) => (
                                                <Chip
                                                      key={value}
                                                      label={value}
                                                      onDelete={() =>
                                                          setSelectedUserRolesForm(
                                                              selectedUserRolesForm.filter((item) => item.name !== value)
                                                          )
                                                      }
                                                      deleteIcon={
                                                          <CancelIcon
                                                              onMouseDown={(event) => event.stopPropagation()}
                                                          />
                                                      }

                                                />
                                            ))}
                                        </Stack>
                                    )}
                                >
                                    {availableRoles.map(role => (
                                        <MenuItem key={role.name} value={role.name}>
                                            {role.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                </FormControl>

                            </Grid>

                        </Grid>
                        <Grid container justifyContent="center">
                        <Grid item>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Submit
                        </Button>
                        <Button
                            color={"error"}
                            variant="contained"
                            onClick={handleCancel}
                            sx={{ mt: 3, mb: 2, ml: 2 }}
                        >
                            Cancel
                        </Button>
                        </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Footer
                    title=""
                    description=""
                />
            </Container>
            </Container>
        </ThemeProvider>
    );
}