import * as React from 'react';
import {useLocation, useNavigate } from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Email from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from "../components/Footer";
import {useEffect, useRef, useState} from "react";
import axios from "../api/axios";
import {Alert} from "@mui/material";


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function ForgotPasswordPage() {

    const navigate = useNavigate()
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const loginForm = useRef(null)
    const [errMsg, setErrMsg] = useState('');

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (loginForm == null || loginForm.current == null ){
            return;
        }
        // Asserting the type of loginForm.current to HTMLFormElement
        const form = loginForm.current as HTMLFormElement;
        const email = form.email.value

        try{
            const response = await axios.post('/users/password-request',
                JSON.stringify({ email: email }),
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
        } catch (err: any) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing email');
            } else if (err.response?.status === 404) {
                //pass
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            }
        }
        navigate(from, { replace: true });
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                { errMsg ? <Alert severity="error">{errMsg}</Alert> :<></>}
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <Email />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot the Password?
                    </Typography>
                    <Typography variant="subtitle1">
                        If the is found account, an email will be sent.
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} ref={loginForm} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="off"
                            autoFocus
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Send Email
                        </Button>
                    </Box>
                </Box>
                <Footer
                    title=""
                    description=""
                />
            </Container>
        </ThemeProvider>
    );
}