import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CoffeeIcon from '@mui/icons-material/Coffee';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useLocation, useNavigate} from 'react-router-dom'
import useAuth from "../hooks/useAuth";
import {useEffect, useState} from "react";
import useLogout from "../hooks/useLogout";
import {RoleType} from "../types";

const pages = [{name: 'Blog', href: '/'},
    {name: 'Create Post', href: '/blogger/post/new'},
    {name: 'Posts', href: '/blogger/posts/edit'},
    {name: 'Users', href: '/admin/users/edit'},
    {name: 'Create User', href: '/admin/user/new'}];

// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const settings = ['Logout'];

function MainAppBar() {
    const location = useLocation();
    const [anchorNav, setAnchorNav] = React.useState<null | HTMLElement>(null);
    const [anchorUser, setAnchorUser] = React.useState<null | HTMLElement>(null);
    const [hasRolePermission, setHasRolePermission] = useState(false);
    const {auth} = useAuth();
    const [isAdmin, setIsAdmin] = useState(auth?.roles?.find(role => ["ADMIN"]?.includes(role['name'])) !== undefined)
    const [loggedIn, setLoggedIn] = useState(Boolean(auth.email));
    const navigate = useNavigate();
    const logout = useLogout();



    useEffect(() => {
        setIsAdmin(auth?.roles?.find(role => ["ADMIN"]?.includes(role['name'])) !== undefined);
    }, []);


    const signOut = async  () => {
        await logout();
    }

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorUser(null);
    };

    function handleNavMenuItemClick(href: string) {
        navigate(href);
    }


    function handleUserSettingClick(setting: string) {

        if (setting === 'Logout') {
            try {
                signOut();
                setLoggedIn(false);
                setAnchorUser(null);
                navigate('/', { replace: true });
            } catch (err) {
                console.error("Failed to logout user.")
                setLoggedIn(true);
            }
        }
    }

    return (
        <>
        {loggedIn && isAdmin ? (
                <>
                <AppBar position="static">
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <CoffeeIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                Pitchblack408
                            </Typography>

                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {pages.map((page) => (
                                        <MenuItem key={page.name} onClick={() => handleNavMenuItemClick(page.href)} >
                                            <Typography textAlign="center">{page.name}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                            <CoffeeIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                            <Typography
                                variant="h5"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'flex', md: 'none' },
                                    flexGrow: 1,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                Pitchblack408
                            </Typography>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {pages.map((page) => (
                                    <Button
                                        key={page.name}
                                        onClick={() => handleNavMenuItemClick(page.href)}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        {page.name}
                                    </Button>
                                ))}
                            </Box>

                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt={`${auth.first_name} ${auth.last_name}`} src="/static/images/avatar/2.jpg" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting} onClick={() => handleUserSettingClick(setting)}>
                                            <Typography textAlign="center">{setting}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
                        </>
                ) : (<div></div>) }
        </>
    );
}
export default MainAppBar;