import * as React from 'react';
import useAuth from '../hooks/useAuth';
import {useLocation, useNavigate, Link as RouterLink} from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Login from '@mui/icons-material/Login';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from "../components/Footer";
import {useEffect, useRef, useState} from "react";
import axios from "../api/axios";
import {Alert} from "@mui/material";
import {stringify} from "querystring";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link"
import {Simulate} from "react-dom/test-utils";
import toggle = Simulate.toggle;


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
const LOGIN_URL = '/users/login'


export default function LogIn() {
    const { setAuth, persist, setPersist } = useAuth();


    const navigate = useNavigate()
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const loginForm = useRef(null)
    const [errMsg, setErrMsg] = useState('');

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (loginForm == null || loginForm.current == null ){
            return;
        }
        // Asserting the type of loginForm.current to HTMLFormElement
        const form = loginForm.current as HTMLFormElement;
        const email = form.email.value
        const password = form.password.value

        try{
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ email: email, password: password }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            const accessToken = response?.data?.access_token;
            const roles = response?.data?.roles;
            const id = response?.data?.id;
            const first_name = response?.data?.first_name;
            const last_name = response?.data?.last_name;
            setAuth({ id, first_name, last_name, email, roles, accessToken });
            navigate(from, { replace: true });
        } catch (err: any) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
        }
    }

    const togglePersist = () => {

        setPersist((prev: any) => !Boolean(prev));
    };
    useEffect(() => {
        localStorage.setItem("persist", persist.toString());
    }, [persist]);

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                { errMsg ? <Alert severity="error">{errMsg}</Alert> :<></>}
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <Login />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Log in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} ref={loginForm} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <FormControlLabel
                            control={<Checkbox value="trust" color="primary" />}
                            label="Trust this device?"
                            onChange={togglePersist}
                            checked={persist}
                        />
                    </Box>
                    <Grid container>
                    <Grid item xs>
                        <Link component={RouterLink} to="/forgot-password" >
                            Forgot password?
                        </Link>
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <Link to="/register" >*/}
                    {/*        {"Don't have an account? Sign Up"}*/}
                    {/*    </Link>*/}
                    {/*</Grid>*/}
                    </Grid>
                </Box>
                <Footer
                    title=""
                    description=""
                />
            </Container>
        </ThemeProvider>
    );
}