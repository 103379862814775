import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CssBaseline from "@mui/material/CssBaseline";
import HomeHeader from "../components/HomeHeader";
import MainAppBar from "../components/MainAppBar";
import Footer from "../components/Footer";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import { ListItemButton } from '@mui/material';


import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useEffect, useState} from "react";
import {Alert, TableContainer} from "@mui/material";
import {Fragment} from "react";
import {convertUtcDateStringToLocalDatetime} from "../utils";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PreviewIcon from '@mui/icons-material/Preview';
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const sections = [
    { title: 'Technology', url: '#' },

]



interface RoleType {
    id: number;
    name: string;
}

interface UserType {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    roles: RoleType[];
    avatar_url:string;
    created: string;
}


const EDIT_USER_URL ='/admin/user/edit/';

const toTitleCase = (str: string) => {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
        }
    );
}

const rolesToString = (roles: RoleType[]) => {
    const roles_str = roles.map((role) => toTitleCase(role.name)).join(', ')
    return roles_str
}



export default function AdminUsersListPage() {

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState<UserType[]>([]);
    const [totalCount, setTotalCount]  = useState<number >(-1);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [perPage, setPerPage]  = useState<number>(10);
    const [alertError, setAlertError]  = useState<string>('');
    const [alertSuccess, setAlertSuccess]  = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosPrivate(`/users?sort=-created&page=${(currentPage + 1).toString()}&per_page=${perPage.toString()}`);
                const jsonData = await response.data;
                setData(jsonData.results);
                setTotalCount(jsonData.pagination.total_items)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentPage, perPage]);

    const handleChangePage = (event: React.MouseEvent | null, newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPerPage(Number(event.target.value));
    };


    const handleDelete = async (event: React.MouseEvent<HTMLElement>,  postId: number) => {
        event.preventDefault();
        setAlertError('');
        setAlertSuccess('');
        const response = await axiosPrivate(`/blog/posts/${postId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const jsonResponse = await response.data;
        if ('error' in jsonResponse) {
            console.error("Error: " + jsonResponse.error.message)
            setAlertError(jsonResponse.error.message);
            window.scrollTo({top: 0, behavior: 'smooth'})
            return;
        }
        window.location.reload();
    };



    return (

        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Container maxWidth="lg">
                <HomeHeader title="Pitchblack408" sections={sections} />
                <MainAppBar/>
                { alertError !== '' ? <Alert severity="error">{alertError}</Alert>:<></> }
                { alertSuccess !== '' ? <Alert severity="success">{alertSuccess}</Alert>:<></> }
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Avatar</TableCell>
                                <TableCell>Username</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Last, Firstname</TableCell>
                                <TableCell>Roles</TableCell>
                                <TableCell>Created LocalDateTime</TableCell>
                                <TableCell>Options</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell >{user.id}</TableCell>
                                    <TableCell ><Avatar alt={`${user.first_name} ${user.last_name}`} src={user.avatar_url ? user.avatar_url : "/static/images/avatar/1.jpg"} /></TableCell>
                                    <TableCell >{user.username}</TableCell>
                                    <TableCell >{user.email}</TableCell>
                                    <TableCell>{`${user.last_name}, ${user.first_name}`}</TableCell>
                                    <TableCell>{rolesToString(user.roles)}</TableCell>
                                    <TableCell>{convertUtcDateStringToLocalDatetime(user.created)}</TableCell>
                                    <TableCell>
                                        {/*<IconButton href={`/user/edit/${user.id}`} >*/}
                                        {/*    <PreviewIcon/>*/}
                                        {/*</IconButton>*/}
                                        <IconButton href={`${EDIT_USER_URL}${user.id}`} >
                                            <EditIcon/>
                                        </IconButton>
                                        {/*<IconButton onClick={(event)=>handleDelete(event, user.id)}>*/}
                                        {/*    <DeleteForeverIcon/>*/}
                                        {/*</IconButton>*/}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container spacing={5} sx={{ mt: 3 }} justifyContent="center">
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        rowsPerPage={perPage}
                        page={currentPage}
                        count={totalCount}
                        component="div"
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleRowsPerPage}

                    >
                    </TablePagination>
                </Grid>
            </Container>
            <Footer
                title=""
                description=""
            />
        </ThemeProvider>
    );
}
