import axios from "../api/axios";
import useAuth from "./useAuth";
import { AuthContextType } from "../context/AuthProvider";


const useRefreshToken = () => {

    const { setAuth } = useAuth();

    const refresh = async  () => {
        const response = await axios.get('users/refresh', {
            withCredentials: true
        });
        setAuth((prev: AuthContextType) => {

            return {
                ...prev,
                id: response.data.id,
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                email: response.data.email,
                roles: response.data.roles,
                accessToken: response.data.access_token }
        });
        return response.data.access_token;
    }
    return refresh;
};

export default useRefreshToken;