import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useEffect, useState} from "react";
import {TableContainer} from "@mui/material";
import {Fragment} from "react";
import {Link as RouterLink} from 'react-router-dom';
// import Link from "@mui/material/Link";
import {convertUtcDateStringToLocal} from "../utils";
import axios from "../api/axios";



interface Post {
  id: number;
  url_title: string;
  title: string;
  description: string;
  body: string;
  created: string;
  author_id: number;
  first_name: string;
  last_name: string;
}


export default function PostsTable() {

  const [data, setData] = useState<Post[]>([]);
  const [totalCount, setTotalCount]  = useState<number >(-1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [perPage, setPerPage]  = useState<number>(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios(`blog/posts?sort=-created&page=${(currentPage + 1).toString()}&per_page=${perPage.toString()}`);
        const jsonData = await response.data;
        setData(jsonData.results);
        setTotalCount(jsonData.pagination.total_items)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currentPage, perPage]);

  const handleChangePage = (event: React.MouseEvent | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPerPage(Number(event.target.value));
  };


  return (
    <Fragment>
      {/*<Title>Blog Posts</Title>*/}
      <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Decription</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((post) => (
              <TableRow key={post.id}
                        component={RouterLink}
                        to={`/post/${post.url_title}`}
                        hover>
                <TableCell>{post.title}</TableCell>
                <TableCell>{post.description}</TableCell>
                <TableCell>{post.first_name+" "+post.last_name}</TableCell>
                <TableCell>{convertUtcDateStringToLocal(post.created)}</TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <Grid container spacing={5} sx={{ mt: 3 }} justifyContent="center">
        <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            rowsPerPage={perPage}
            page={currentPage}
            count={totalCount}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPage}

        >
        </TablePagination>
      </Grid>
    </Fragment>
  );
}
