import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {Post} from '../types';
import Paper from '@mui/material/Paper';
import ReaderPostsTable from './ReaderPostsTable';



interface MainProps {
    header_title: string;
    posts: Post[]; // Now it accepts an array of Post objects
}


export default function Main(props: MainProps) {
    const { posts,  header_title} = props;


    return (
        <Grid
            item
            xs={12}
            md={8}
            sx={{
                '& .markdown': {
                    py: 3,
                },
            }}
        >
            <Typography variant="h6" gutterBottom>
                {header_title}
            </Typography>
            <Divider />
            {/*{posts.map((post) => (*/}
            {/*    <Markdown className="markdown" key={post.body.substring(0, 40)}>*/}
            {/*        {post}*/}
            {/*    </Markdown>*/}
            {/*))}*/}
            <Grid container sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <ReaderPostsTable />
                </Paper>
              </Grid>
            </Grid>
        </Grid>
    );
}
