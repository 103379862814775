import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useEffect, useState} from "react";
import {Alert, TableContainer} from "@mui/material";
import {Fragment} from "react";
import {convertUtcDateStringToLocalDatetime} from "../utils";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PreviewIcon from '@mui/icons-material/Preview';
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

interface Post {
  id: number;
  url_title: string;
  title: string;
  description: string;
  body: string;
  created: string;
  author_id: number;
  first_name: string;
  last_name: string;
}

export default function PostsTable() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState<Post[]>([]);
  const [totalCount, setTotalCount]  = useState<number >(-1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [perPage, setPerPage]  = useState<number>(10);
  const [alertError, setAlertError]  = useState<string>('');
  const [alertSuccess, setAlertSuccess]  = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate(`blog/posts?sort=-created&page=${(currentPage + 1).toString()}&per_page=${perPage.toString()}`);
        const jsonData = await response.data;
        setData(jsonData.results);
        setTotalCount(jsonData.pagination.total_items)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currentPage, perPage]);

  const handleChangePage = (event: React.MouseEvent | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPerPage(Number(event.target.value));
  };


  const handleDelete = async (event: React.MouseEvent<HTMLElement>,  postId: number) => {
    event.preventDefault();
    setAlertError('');
    setAlertSuccess('');
    const response = await axiosPrivate(`blog/posts/${postId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const jsonResponse = await response.data;
    if ('error' in jsonResponse) {
      console.error("Error: " + jsonResponse.error.message)
      setAlertError(jsonResponse.error.message);
      window.scrollTo({top: 0, behavior: 'smooth'})
      return;
    }
    window.location.reload();
  };
  return (

    <Fragment>
      { alertError !== '' ? <Alert severity="error">{alertError}</Alert>:<></> }
      { alertSuccess !== '' ? <Alert severity="success">{alertSuccess}</Alert>:<></> }
      <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Date, LocalTime</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((post) => (
              <TableRow key={post.id}>
                <TableCell >{post.id}</TableCell>
                <TableCell >{post.title}</TableCell>
                <TableCell>{post.description}</TableCell>
                <TableCell>{post.first_name+" "+post.last_name}</TableCell>
                <TableCell>{convertUtcDateStringToLocalDatetime(post.created)}</TableCell>
                <TableCell>
                  <IconButton href={`/blogger/post/preview/${post.url_title}`} >
                    <PreviewIcon/>
                  </IconButton>
                  <IconButton href={`/blogger/post/edit/${post.url_title}`} >
                      <EditIcon/>
                  </IconButton>
                  <IconButton onClick={(event)=>handleDelete(event, post.id)}>
                    <DeleteForeverIcon/>
                  </IconButton>
                </TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <Grid container spacing={5} sx={{ mt: 3 }} justifyContent="center">
        <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            rowsPerPage={perPage}
            page={currentPage}
            count={totalCount}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPage}

        >
        </TablePagination>
      </Grid>
    </Fragment>
  );
}
