import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';
import ErrorPage from './pages/ErrorPage';
import Home from './pages/HomePage';
import Login from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import PostReaderPage from "./pages/PostReaderPage";
import PostBloggerEditPage from "./pages/PostBloggerEditPage";
import PasswordResetPage from './pages/PasswordResetPage'
import PostBloggerPreviewPage from "./pages/PostBloggerPreviewPage";
// import PostReaderPage from "./pages/PostReaderPage";
// import PostBloggerEditPage from "./pages/PostBloggerEditPage";
import PostBloggerListPage from "./pages/PostBloggerListPage";
// import PostBloggerPreviewPage from "./pages/PostBloggerPreviewPage";
// import RegisterPage from "./pages/RegisterPage";
import PersistLogin from "./components/PersistLogin";
import Unauthorized from "./pages/Unauthorized";
import {OfflinePin} from "@mui/icons-material";
import OptionalAuth from "./components/OptionalAuth";
import AdminUsersListPage from "./pages/AdminUsersListPage";
import UserEditPage from './pages/UserEditPage';
import RegisterPage from "./pages/RegisterPage";
import UserNewPage from './pages/UserNewPage';
import PostBloggerNewPage from "./pages/PostBloggerNewPage";


const ROLES = {
    'User': 'USER',
    'Editor': 'EDITOR',
    'Admin': 'ADMIN'
}

function App() {

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                {/* public routes */}

                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/password-reset" element={<PasswordResetPage />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/post/:urlTitle" element={<PostReaderPage />} />


                {/* we want to protect these routes */}
                <Route element={<PersistLogin/>} >
                    <Route element={<OptionalAuth/>}>
                        <Route index element={<Home />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />} >
                        <Route path="/admin/users/edit" element={<AdminUsersListPage/>} />
                        <Route path="/admin/user/new" element={<UserNewPage/>} />
                        <Route path="/admin/user/edit/:id?" element={<UserEditPage/>} />
                    </Route>
                    {/* we want to protect these routes */}
                    <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Editor]} />}>
                        <Route path="/blogger/post/edit/:urlTitle?" element={<PostBloggerEditPage />} />
                        <Route path="/blogger/post/new" element={<PostBloggerNewPage />} />
                        <Route path="/blogger/posts/edit" element={<PostBloggerListPage />} />
                        <Route path="/blogger/post/preview/:urlTitle?" element={<PostBloggerPreviewPage />} />
                    </Route>
                </Route>
                {/* catch all */}
                <Route path="*" element={<ErrorPage />} />
            </Route>
        </Routes>
    );
}

export default App;