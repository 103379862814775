import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";


interface RequireAuthProps {
    allowedRoles: string[]; // Assuming roles are strings, adjust type if necessary
}

const RequireAuth: React.FC<RequireAuthProps> = ({ allowedRoles  }) => {
    const { auth } = useAuth();
    const location = useLocation();

    return (
        (auth?.roles?.find(role => allowedRoles?.includes(role['name'])) !== undefined)
            ? <Outlet />
            : auth?.email
                ?
                <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;