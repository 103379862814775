import axios from 'axios';

// The url needs to be in this format http://localhost:5000/api/v1/
const BACKEND_BASE_URL  = process.env.REACT_APP_BACKEND_BASE_URL;

export default axios.create({
    baseURL: BACKEND_BASE_URL
});


export const axiosPrivate = axios.create({
    baseURL: BACKEND_BASE_URL,
    headers: {'Content-Type': 'application/json'},
    withCredentials: true
});


