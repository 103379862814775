import * as React from 'react';
import Typography from '@mui/material/Typography';


export interface AuthorHeaderProps {
    title: string;
    author: string;
    created: string;
}

export default function AuthorHeader(props: AuthorHeaderProps) {
    const { title, author, created} = props;

    return (
        <React.Fragment>
            <header style={{ backgroundColor: '#f4f4f4', padding: '20px', borderBottom: '2px solid #ddd', textAlign: 'center' }}>
                <Typography variant="h1" style={{ fontFamily: 'Times New Roman, Times, serif', fontSize: '36px', letterSpacing: '2px', textTransform: 'uppercase', color: '#333', lineHeight: '1', margin: '0' }}>
                    {title}
                </Typography>
                <div className="news-info" style={{ marginTop: '10px', fontSize: '14px' }}>
                    <span className="author" style={{ marginRight: '20px' }}>Author: {author}</span>
                    <span className="created" style={{ color: '#666' }}>Date: {created}</span>
                </div>
            </header>
        </React.Fragment>
    );
}
